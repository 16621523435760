<template>
<div>
    <span v-b-modal="'show-template'+additionalKey" @click="clicked = !clicked">{{ $t(mainText) }}</span>
    <b-modal class="modal-width-max" ref="my-modal" hide-footer centered :cancel-title="$t('start-with-empty-process')" :ok-title="$t('use-template')" size="xl" scrollable :title="$t('Start-with-template')" :id="'show-template'+additionalKey">
        <b-row>
            <b-col cols="3">
                <vue-tags-input class="mb-1" v-on:tags-changed="searchDiagramByTag" :placeholder="$t('Search-by-tags')" v-model="tag" :tags="tags" :autocomplete-items="filteredItems" @tags-changed="newTags => tags = newTags" />
                <b-list-group class="full-list">
                    <b-list-group-item :active="selectedTemplate != null && selectedTemplate.id == item.id  " class="list-template" @click="setActive(item)" href="#" v-bind:key="item.diagramId" v-for="item in diagrams">

                        <h5 class="mb-1">{{item.name}}</h5>

                        <b-badge size="sm" class="ml-1" variant="light" pill v-bind:key="tag" v-for="tag in item.tags"> {{tag}} </b-badge>
                    </b-list-group-item>

                </b-list-group>
            </b-col>
            <b-col cols="9">
                <div v-if="selectedTemplate">
                    <the-diagram :customHeight="customHeight" :key="counter" :showViewer="true" :diagramBody="selectedTemplate.body"> </the-diagram>
                    <hr>
                    <vue-markdown :key="counter" :html="false"> {{selectedTemplate.description}}</vue-markdown>
                </div>
                <b-btn block size="lg" variant="primary" v-if="!isAuthenticated && selectedTemplate" to="/app/signup" @click="$bvModal.hide('show-template')"> {{$t('free-signup-for-template')}} </b-btn>
                <b-btn-group class="float-xl-right" v-if="selectedTemplate && isAuthenticated">
                    <b-btn @click="$bvModal.hide('show-template')" to="/app/diagram/" variant="outline-secondary "> {{ $t('start-with-empty') }} </b-btn>
                    <b-btn @click="setTemplateBody" variant="primary"> {{$t('Use-selected-tempalte')}} </b-btn>
                </b-btn-group>
            </b-col>
        </b-row>
    </b-modal>
</div>
</template>

<script>
import {
    AXIOS
} from '@/shared/http-common.js'
export default {
    name: "StartFromTemplate",
    props: {
        mainText: {
            type: String,
            default: "Use-template"
        },
        additionalKey: {
            type: String,
            default: "key"
        }
    },

    components: {
        TheDiagram: () => import("@/widgets/diagrams/diagram-editor.vue")
    },
    data() {
        return {
            tag: '',
            tags: [],
            clicked: false,
            filteredItems: [],
            counter: 0,
            description: null,
            diagrams: [],
            selectedTemplate: null
        }
    },
    created() {

    },
    watch: {
        clicked() {
            AXIOS.get("/tags").then(response => {
                this.filteredItems = response.data.tags;
            })
            AXIOS.post('/template/search').then(response => {
                this.diagrams = response.data;
                if (this.diagrams.length == 0) {
                    this.$router.push("/app/diagram/");
                }
            })
        }
    },
    computed: {
        customHeight() {
            if (this.diagrams.length > 5) {
                return 5 * 100
            } else return 400;
        },
        isAuthenticated() {
            return this.$store.getters.isAuthenticated;
        },
    },
    mounted() {

    },
    methods: {
        setTemplateBody() {

            AXIOS.post("/template/createDiagram/" + this.selectedTemplate.id).then(response => {
                this.$refs['my-modal'].hide()

                this.$router.push('/app/diagram/' + response.data.id)
            })

        },
        setBody(body) {
            this.$store.commit("setCurrentDigaramBody", body);
        },
        searchDiagramByTag(tags) {

            var obj = [];

            tags.forEach(element => {
                obj.push(element.text)
            });

            AXIOS.post('/template/search', obj).then(response => {
                this.diagrams = response.data;
            })
        },
        setActive(item) {

            this.selectedTemplate = item
            this.counter = this.counter + 1;
        }
    }
}
</script>

<style>
.full-list {
    word-wrap: break-word;
    max-height: 800px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
}
</style>
